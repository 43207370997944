import { PhoneNumber } from '@/types/phoneNumber'
import { TaskSid } from '@/types/sid'
import { Task } from '@/types/task'
import { TaskAttributes } from '@/types/task-attributes'
import { TokenExpiredError } from '@/types/token-expired-error'
import { useAcceptedCall } from './useAcceptedCall'
import { AuthResponse, useApi } from './useApi'
import { useDevice } from './useDevice'
import { useExternalAuth } from './useExternalAuth'
import { useOutboundCallDial } from './useOutboundDialState'
import { useOutsideAppEmitter } from './useOutsideEmitter'
import { useRinger } from './useRinger'
import { useSync } from './useSync'
import { useSyncDocToAcceptedCall } from './useSyncDocToAcceptedCall'
import { useTwilioAppEmitter } from './useTwilioAppEmitter'
import { useWidgetAuth } from './useWidgetAuth'
import { useWidgetEmitter } from './useWidgetEmitter'
import { useWidgetStore, WidgetLoading } from './useWidgetStore'
import { useWorker } from './useWorker'
import { BaseTask, useWorkerTask, WorkerTask } from './useWorkerTask'
import { useWorkspace } from './useWorkspace'

function taskToWorkerTask(
    task: Task<TaskAttributes>,
    phoneNumbers: Readonly<PhoneNumber[]>
): WorkerTask {
    function getSystemAndContactPhoneNumbers() {
        const { attributes } = task
        const { to, from, __type } = attributes

        if (__type === 'warm_transfer') {
            return {
                contact: attributes.__customer_identity,
                system: attributes.__agent_phone_number,
            }
        }

        if (
            __type === 'outgoing_call' ||
            (__type === 'transfer_to_queue' &&
                attributes.__original_call_direction === 'outgoing_call')
        ) {
            return {
                contact: to,
                system: from,
            }
        }

        return {
            contact: from,
            system: to,
        }
    }

    const { contact, system } = getSystemAndContactPhoneNumbers()

    const phoneNumber = phoneNumbers.find(
        (value) => value.phoneNumber === system
    ) || { phoneNumber: system, friendlyName: system }

    const { sid, taskQueueFriendlyName, attributes } = task

    const baseTask: BaseTask = {
        taskSid: sid as TaskSid,
        phoneNumber,
        contact: { id: null, avatar: null, name: null, phoneNumber: contact },
        acceptedCall: null,
        status: 'none',
        taskQueue: taskQueueFriendlyName,
    }

    switch (attributes.__type) {
        case 'incoming_call':
        case 'transfer_to_queue':
            return {
                ...baseTask,
                type: 'incoming_call',
            }
        case 'outgoing_call':
            return {
                ...baseTask,
                type: 'outgoing_call',
            }
        case 'warm_transfer': {
            return {
                ...baseTask,
                type: 'warm_transfer',
                transferFrom: attributes.__from_friendly_name,
            }
        }
    }
}

export function useTwilioApp(phoneNumbers: PhoneNumber[]) {
    const worker = useWorker()
    const auth = useWidgetAuth()
    const workspace = useWorkspace()
    const device = useDevice()
    const sync = useSync()
    const ringer = useRinger()
    const twilioEmitter = useTwilioAppEmitter()
    const widgetEmitter = useWidgetEmitter()
    const { emitter: ousideEmitter, workerTaskToCallPayload } =
        useOutsideAppEmitter()
    const api = useApi()
    const outboundDial = useOutboundCallDial()
    const {
        incomingCallAccepted,
        initAcceptedCall,
        getParticipantData,
        getCustomerParticipantData,
        removeWorkerParticipant,
        getCustomerCallSid,
    } = useSyncDocToAcceptedCall()

    function removeAllEmitterListeners() {
        widgetEmitter.removeAllListeners()
        twilioEmitter.removeAllListeners()
    }

    async function init(
        payload: {
            identity: string
            worker_sid: string
            refreshTokenUrl: string
            refreshToken: string
        },
        refetchTokensCount = 0
    ): Promise<void> {
        const externalAuth = useExternalAuth()
        const widget = useWidgetStore()
        const sdkCount = 4

        try {
            await externalAuth.init(payload)
        } catch (error) {
            console.error(`Refresh token is wrong or expired.`)
            return widget.setGeneralError(`Refresh token is wrong or expired.`)
        }

        if (refetchTokensCount === sdkCount) {
            console.error(
                `Refreshed tokens ${sdkCount} times in a session. Something went wrong.`
            )
            return widget.setGeneralError(`Something went wrong.`)
        }

        removeAllEmitterListeners()

        try {
            const data = await auth.init({
                refreshToken: externalAuth.getRefreshToken(),
            })
            const {
                workerData: { workerDetails, activities, reservations },
                taskQueues,
            } = await initTwilio(data)
            widget.initWorkerState({
                name: workerDetails.friendlyName,
                activity: {
                    sid: workerDetails.activitySid,
                    friendlyName: workerDetails.activityName,
                    available: workerDetails.available,
                },
                activities: activities.map((activity) => ({
                    sid: activity.sid,
                    friendlyName: activity.friendlyName,
                    available: activity.available,
                })),
                taskQueues: taskQueues.map((queue) => ({
                    sid: queue.sid,
                    friendlyName: queue.friendlyName,
                })),
                phoneNumbers,
            })

            if (reservations[0]) {
                widget.setWorkerTask(
                    taskToWorkerTask(reservations[0].task, phoneNumbers)
                )
            }

            ousideEmitter.emit('inited')
        } catch (error: any) {
            if (error instanceof TokenExpiredError) {
                return auth
                    .fetchTokens({
                        refreshToken: externalAuth.getRefreshToken(),
                    })
                    .then(() => init(payload, refetchTokensCount + 1))
                    .catch((error) => {
                        console.error('widget error', error)
                        widget.setGeneralError(
                            `Failed initializing app: ${error.message}`
                        )
                    })
            }

            console.error('widget error', error)
            widget.setGeneralError(`Failed initializing app: ${error.message}`)
        }
    }

    async function initTwilio({
        workerToken,
        connectActivitySid,
        disconnectActivitySid,
        onACallActivitySid,
        deviceToken,
        workspaceToken,
        syncToken,
    }: AuthResponse) {
        let workerData: Awaited<ReturnType<typeof worker.init>>
        let taskQueues: Awaited<ReturnType<typeof workspace.init>>
        addEventListeners()

        workerData = await worker.init(workerToken, {
            connectActivitySid,
            disconnectActivitySid,
            onACallActivitySid,
        })
        taskQueues = await workspace.init(workspaceToken)
        await device.init(deviceToken)
        await sync.init(syncToken)
        return { workerData, taskQueues }
    }

    function getTaskBySid(taskSid: TaskSid): WorkerTask {
        const widget = useWidgetStore()
        const task = worker.getTaskBySid(taskSid)

        if (widget.workerTask?.taskSid === taskSid) {
            return { ...widget.workerTask }
        }

        return taskToWorkerTask(task, widget.phoneNumbers)
    }

    async function holdParticipant({
        taskSid,
        identity,
        conferenceSid,
        callSid,
        hold,
    }: {
        taskSid: TaskSid
        identity: string
        callSid: string
        conferenceSid: string
        hold: boolean
    }) {
        await sync.toggleHoldLoading(taskSid, identity, true)
        await api.holdParticipant({
            conference_sid: conferenceSid,
            call_sid: callSid,
            hold: hold,
        })
        await sync.setParticipantHold(taskSid, identity, hold)
    }

    function getOriginalCallDirection(task: Task<TaskAttributes>) {
        return task.attributes.__type === 'transfer_to_queue' ||
            task.attributes.__type === 'warm_transfer'
            ? task.attributes.__original_call_direction
            : task.attributes.__type
    }

    function getOriginalTaskSid(task: Task<TaskAttributes>) {
        return task.attributes.__type === 'warm_transfer'
            ? task.attributes.__origin_task_sid
            : (task.sid as TaskSid)
    }

    async function onRejectTask(taskSid: TaskSid) {
        const widget = useWidgetStore()
        const { setTaskStatus } = useWorkerTask()
        const { unavailableActivitySid } = useWidgetAuth()
        const workerTask = getTaskBySid(taskSid)

        try {
            widget.setWorkerTask(setTaskStatus(workerTask, 'loading'))
            await worker.rejectReservation(taskSid, unavailableActivitySid)
            const workerDetails = worker.workerDetails
            ousideEmitter.emit('incomingCallRejected', {
                ...workerTaskToCallPayload(workerTask),
                worker: workerDetails,
            })

            if (workerTask.type === 'warm_transfer') {
                const task = worker.getTaskBySid(taskSid)
                const originTaskSid = getOriginalTaskSid(task)
                removeWorkerParticipant(originTaskSid, workerDetails.identity)
            }
            widget.setWorkerTask(null)
        } catch (error: any) {
            widget.setWorkerTask(
                setTaskStatus(workerTask, {
                    error: error.message,
                })
            )
        }
    }

    function addEventListeners() {
        const externalAuth = useExternalAuth()
        twilioEmitter
            .on('workerUpdated', (worker) => {
                const widget = useWidgetStore()
                const activity = widget.activity

                if (activity && activity.sid !== worker.accountSid) {
                    ousideEmitter.emit('activityUpdated', {
                        worker: {
                            sid: worker.sid,
                            identity: worker.attributes.contact_uri,
                            friendlyName: worker.friendlyName,
                        },
                        previousActivity: {
                            sid: activity.sid,
                            friendlyName: activity.friendlyName,
                            available: activity.available,
                        },
                        newActivity: {
                            sid: worker.activitySid,
                            friendlyName: worker.activityName,
                            available: worker.available,
                        },
                    })
                }

                widget.setWorker(worker.friendlyName, {
                    sid: worker.activitySid,
                    available: worker.available,
                    friendlyName: worker.activityName,
                })
            })
            .on('sdkDisconnected', (sdk) => {
                const widget = useWidgetStore()
                device.destroy()
                sync.destroy()
                widget.setSdkDisconnected(sdk)
                ousideEmitter.emit('disconnected')
            })
            .on('sdkError', ({ error, sdk }) => {
                if (sdk === 'device' && error.code === 31005) {
                    const widget = useWidgetStore()
                    widget.setSdkDisconnected(sdk)
                }
                console.log('SDK ERROR =>', sdk)
                console.log(error)
                ousideEmitter.emit('error', error)
            })
            .on('sdkConnected', async (sdk) => {
                const widget = useWidgetStore()
                console.log('SDK CONNECTED =>', sdk)

                const { identityData } = useWidgetAuth()

                if (!identityData) {
                    return
                }

                if (widget.isAnySdkDisconnected) {
                    console.log('Re-initing app')
                    try {
                        auth.destroy()
                        await init({
                            identity: identityData.identity,
                            worker_sid: identityData.sid,
                            ...externalAuth.latestState(),
                        })
                        ousideEmitter.emit('connected')
                    } catch (error: any) {
                        console.error('Error on re init')
                        widget.setGeneralError(error.message)
                    }
                }
            })
            .on('reservationCreated', async (reservation) => {
                const widget = useWidgetStore()
                const { unavailableActivitySid } = useWidgetAuth()
                const task = taskToWorkerTask(
                    reservation.task as Task<TaskAttributes>,
                    widget.phoneNumbers
                )
                const currentContact = widget.currentContact

                if (currentContact) {
                    task.contact = currentContact
                }

                // We should reject the reservation if there is an outgoing call in progress
                if (
                    reservation.task.attributes.__type !== 'outgoing_call' &&
                    widget.state.status === 'loading' &&
                    'to' in ((widget.state as WidgetLoading).content ?? {})
                ) {
                    await worker.rejectReservation(
                        task.taskSid,
                        unavailableActivitySid
                    )
                    return
                }

                widget.setWorkerTask(task)

                if (reservation.task.attributes.__type !== 'outgoing_call') {
                    ousideEmitter.emit(
                        'incomingCall',
                        workerTaskToCallPayload(task)
                    )
                    return ringer.playIncomingAudio()
                }

                worker.acceptReservation(reservation.task.sid as TaskSid)
                ringer.playOutgoingAudio()
                ousideEmitter.emit('outgoingCall', {
                    ...workerTaskToCallPayload(task),
                    worker: worker.workerDetails,
                })
            })
            .on('reservationAccepted', async (reservation, worker) => {
                const widget = useWidgetStore()
                const { setAcceptedCall, setTaskStatus } = useWorkerTask()

                if (
                    reservation.task.attributes.__type === 'outgoing_call' ||
                    !widget.workerTask
                ) {
                    return
                }

                const task = { ...widget.workerTask }

                ringer.stopAudio()

                try {
                    const acceptedCall = await incomingCallAccepted(
                        reservation.task,
                        worker.attributes.contact_uri,
                        worker.friendlyName,
                        task.contact
                    )

                    widget.setWorkerTask(setAcceptedCall(task, acceptedCall))
                    ousideEmitter.emit('incomingCallAccepted', {
                        ...workerTaskToCallPayload(task),
                        worker: {
                            sid: worker.sid,
                            identity: worker.attributes.contact_uri,
                            friendlyName: worker.friendlyName,
                        },
                        acceptedAt: Date.now(),
                        customerCallSid: getCustomerCallSid(task.taskSid),
                    })
                } catch (error: any) {
                    widget.setWorkerTask(
                        setTaskStatus(task, { error: error.message })
                    )
                }
            })
            .on('reservationRemoved', (reservation) => {
                const widget = useWidgetStore()
                ringer.stopAudio()
                const originTaskSid = getOriginalTaskSid(reservation.task)
                sync.removeDocFromSet(originTaskSid)
                const workerTask = widget.workerTask
                widget.setWorkerTask(null)

                if (!workerTask) {
                    return
                }

                const call = workerTaskToCallPayload(workerTask)
                const workerDetails = worker.workerDetails

                if (workerTask.acceptedCall) {
                    const durationSeconds = Math.floor(
                        (Date.now() -
                            workerTask.acceptedCall.acceptedTimestamp) /
                            1000
                    )
                    if (workerTask.type === 'outgoing_call') {
                        return ousideEmitter.emit('outgoingCallEnded', {
                            ...call,
                            worker: workerDetails,
                            durationSeconds,
                        })
                    }

                    return ousideEmitter.emit('incomingCallEnded', {
                        ...call,
                        worker: workerDetails,
                        durationSeconds,
                    })
                }

                if (workerTask.type !== 'outgoing_call') {
                    return ousideEmitter.emit('incomingCallCanceled', {
                        ...call,
                        worker: workerDetails,
                    })
                }

                ousideEmitter.emit('outgoingCallRejected', {
                    ...call,
                    worker: workerDetails,
                })
            })
            .on('syncDocUpdated', ({ taskSid, doc }) => {
                const widget = useWidgetStore()
                if (!('participants' in doc) || !widget.workerTask) {
                    return
                }

                try {
                    const task = worker.getTaskBySid(widget.workerTask.taskSid)
                    const taskSidOrOriginTaskSid = getOriginalTaskSid(task)

                    if (!taskSidOrOriginTaskSid) {
                        return
                    }

                    if (taskSidOrOriginTaskSid !== taskSid) {
                        console.warn(
                            `Update for task, which is not current | current: ${taskSidOrOriginTaskSid} | update: ${taskSid}`
                        )
                        return
                    }

                    const workerTask = getTaskBySid(widget.workerTask.taskSid)
                    const acceptedCall = initAcceptedCall(
                        doc,
                        worker.workerDetails.identity
                    )
                    const clientParticipant = acceptedCall.participants.find(
                        (participant) => participant.isClient
                    )

                    if (workerTask.type === 'outgoing_call') {
                        if (clientParticipant?.callStatus !== 'in-progress') {
                            return
                        } else if (!workerTask.acceptedCall) {
                            ringer.stopOutgoingAudio()
                            ousideEmitter.emit('outgoingCallAccepted', {
                                ...workerTaskToCallPayload(workerTask),
                                worker: worker.workerDetails,
                                acceptedAt: Date.now(),
                            })
                        }
                    }

                    const { updateAcceptedCallParticipants } = useWorkerTask()
                    widget.setWorkerTask(
                        updateAcceptedCallParticipants(workerTask, acceptedCall)
                    )
                } catch (error) {
                    console.error(`Error in syncDocUpdated: ${error}`)
                }
            })

        widgetEmitter
            .on('setOutboundContact', async (contact, fromPhoneNumber) => {
                const widget = useWidgetStore()
                if (widget?.workerTask?.taskSid) {
                    await onRejectTask(widget.workerTask.taskSid)
                }
                if (!worker.isAvailable) {
                    const activity = await worker.goToAvailable()

                    if (activity) {
                        widget.setWorkerActitivy(activity, 'loaded')
                    }
                }
                widget.setOutboundContact({ contact, fromPhoneNumber })
            })
            .on('setPhoneNumbers', (phoneNumbers) => {
                const widget = useWidgetStore()
                widget.setPhoneNumbers(phoneNumbers)
            })
            .on('setContact', (contact) => {
                const widget = useWidgetStore()
                widget.setContact(contact)
            })
            .on('acceptTask', async (taskSid) => {
                const widget = useWidgetStore()
                const { setTaskStatus } = useWorkerTask()
                const task = getTaskBySid(taskSid)

                try {
                    widget.setWorkerTask(setTaskStatus(task, 'loading'))
                    await worker.acceptReservation(taskSid)
                    device.acceptActiveCall()
                } catch (error: any) {
                    widget.setWorkerTask(
                        setTaskStatus(task, {
                            error: error.message,
                        })
                    )
                }
            })
            .on('rejectTask', onRejectTask)
            .on('endCall', async (taskSid) => {
                const widget = useWidgetStore()
                const { setTaskStatus } = useWorkerTask()
                const task = getTaskBySid(taskSid)

                try {
                    const workerDetails = worker.workerDetails
                    widget.setWorkerTask(setTaskStatus(task, 'loading'))
                    device.toggleMuted(true)
                    worker.completeReservation(taskSid)
                    device.disconnectAll()
                    if (task.acceptedCall) {
                        const durationSeconds = Math.floor(
                            (Date.now() - task.acceptedCall.acceptedTimestamp) /
                                1000
                        )
                        if (task.type === 'outgoing_call') {
                            ousideEmitter.emit('outgoingCallEnded', {
                                ...workerTaskToCallPayload(task),
                                worker: workerDetails,
                                durationSeconds,
                            })
                        } else {
                            ousideEmitter.emit('incomingCallEnded', {
                                ...workerTaskToCallPayload(task),
                                worker: workerDetails,
                                durationSeconds,
                            })
                        }
                    }
                    widget.setWorkerTask(null)
                } catch (error: any) {
                    widget.setWorkerTask(
                        setTaskStatus(task, { error: error.message })
                    )
                }
            })
            .on('cancelCall', async (taskSid) => {
                const widget = useWidgetStore()
                const { setTaskStatus } = useWorkerTask()
                const task = getTaskBySid(taskSid)

                try {
                    const workerDetails = worker.workerDetails
                    widget.setWorkerTask(setTaskStatus(task, 'loading'))
                    device.toggleMuted(true)
                    worker.completeReservation(taskSid)
                    device.disconnectAll()
                    ousideEmitter.emit('outgoingCallCanceled', {
                        ...workerTaskToCallPayload(task),
                        worker: workerDetails,
                    })
                    widget.setWorkerTask(null)
                } catch (error: any) {
                    widget.setWorkerTask(
                        setTaskStatus(task, { error: error.message })
                    )
                }
            })
            .on('makeOutboundCall', async (params) => {
                const widget = useWidgetStore()
                const { setStatus } = useOutboundCallDial()

                try {
                    const dial = widget.updateOutgoingCallDial((dial) =>
                        setStatus(dial, 'loading')
                    )

                    if (!dial) {
                        throw Error('No dial in state')
                    }

                    const workerDetails = worker.workerDetails
                    const { task_sid } = await api.makeCall({
                        to: params.to,
                        from: params.from,
                        worker_identity: workerDetails.identity,
                        worker_sid: workerDetails.sid,
                        worker_friendly_name: workerDetails.friendlyName,
                        customer_friendly_name: dial.contact?.name || dial.to,
                    })
                    await sync.subToDoc(task_sid)
                } catch (error: any) {
                    const message = error.message || error.error
                    return widget.updateOutgoingCallDial((dial) =>
                        setStatus(dial, { error: message })
                    )
                }
            })
            .on('updateActivity', async (activity) => {
                const widget = useWidgetStore()
                try {
                    widget.setWorkerActitivy(activity, 'loading')
                    await worker.updateActivity(activity.sid)
                    widget.setWorkerActitivy(activity, 'loaded')
                } catch (error: any) {
                    console.log(error)
                    widget.setWorkerActitivy(activity, { error: error.message })
                }
            })
            .on('toggleOutgoingDial', (value) => {
                const widget = useWidgetStore()
                widget.toggleOutgoingCall(value)
            })
            .on('selectOutgoingPhoneNumber', (phoneNumber) => {
                const widget = useWidgetStore()
                widget.updateOutgoingCallDial((dial) =>
                    outboundDial.setPhoneNumber(dial, phoneNumber)
                )
            })
            .on('inputOutgoingDialNumber', (phoneNumber) => {
                const widget = useWidgetStore()
                ousideEmitter.emit('inputOutgoingPhoneNumber', { phoneNumber })
                widget.updateOutgoingCallDial((dial) =>
                    outboundDial.setTo(dial, phoneNumber)
                )
            })
            .on('toggleMuted', () => {
                const widget = useWidgetStore()
                const { toggleMuted } = useAcceptedCall()
                const acceptedCall = widget.updateAcceptedCall((call) =>
                    toggleMuted(call)
                )
                if (!acceptedCall) {
                    return console.error('No accepted call in state')
                }
                device.toggleMuted(acceptedCall.isMuted)
                const task = widget.workerTask

                if (!task) {
                    return
                }

                ousideEmitter.emit('changeCallMuted', {
                    ...workerTaskToCallPayload(task),
                    worker: worker.workerDetails,
                    isMuted: acceptedCall.isMuted,
                })
            })
            .on('toggleDialpadOpened', () => {
                const widget = useWidgetStore()
                const { toggleDialpadOpened } = useAcceptedCall()
                widget.updateAcceptedCall((call) => toggleDialpadOpened(call))
            })
            .on('contactClicked', (contact) => {
                ousideEmitter.emit('contactClicked', { contact })
            })
            .on('inputDialpadValue', (value) => {
                const widget = useWidgetStore()
                const { setDialpadValue } = useAcceptedCall()
                widget.updateAcceptedCall((call) =>
                    setDialpadValue(call, value)
                )
                const task = widget.workerTask
                const lastDigit = value.slice(-1)
                if (!lastDigit) {
                    return
                }

                device.sendDigits(lastDigit)
                if (!task) {
                    return
                }
                ousideEmitter.emit('inputCallDigit', {
                    ...workerTaskToCallPayload(task),
                    worker: worker.workerDetails,
                    latestDigit: lastDigit,
                    entered: value,
                })
            })
            .on('toggleParticipantHold', async (taskSid, identity) => {
                const task = worker.getTaskBySid(taskSid)
                const widget = useWidgetStore()

                if (!task) {
                    console.error(
                        `There is no task with this sid ${taskSid} in state`
                    )
                    return
                }

                const originTaskSid = getOriginalTaskSid(task)

                try {
                    const { conferenceSid, participant } = getParticipantData(
                        originTaskSid,
                        identity
                    )
                    await sync.toggleHoldLoading(originTaskSid, identity, true)
                    await api.holdParticipant({
                        conference_sid: conferenceSid,
                        call_sid: participant.callSid,
                        hold: !participant.hold,
                    })
                    const workerTask = widget.workerTask
                    if (workerTask) {
                        ousideEmitter.emit('changeParticipantHold', {
                            ...workerTaskToCallPayload(workerTask),
                            worker: worker.workerDetails,
                            participant: {
                                type:
                                    participant.participantLabel === 'Customer'
                                        ? 'client'
                                        : participant.participantLabel ===
                                            'ExternalParticipant'
                                          ? 'external'
                                          : 'worker',
                                identity: participant.identity,
                            },
                            hold: !participant.hold,
                        })
                    }
                    await sync.setParticipantHold(
                        originTaskSid,
                        identity,
                        !participant.hold
                    )
                } catch (error) {
                    await sync.toggleHoldLoading(originTaskSid, identity, false)
                }
            })
            .on('toggleForwarding', () => {
                const widget = useWidgetStore()
                const { toggleForwarding } = useAcceptedCall()
                widget.updateAcceptedCall((call) =>
                    toggleForwarding(call, widget.availableTaskQueues)
                )
            })
            .on('toggleAddParticipant', () => {
                const widget = useWidgetStore()
                const { toggleAddParticipant } = useAcceptedCall()
                widget.updateAcceptedCall((call) => toggleAddParticipant(call))
            })
            .on('coldTransferToPhoneNumber', async (taskSid, phone_number) => {
                const widget = useWidgetStore()
                const { setForwardingStatus } = useAcceptedCall()
                widget.updateAcceptedCall((call) =>
                    setForwardingStatus(call, 'loading')
                )
                try {
                    const task = worker.getTaskBySid(taskSid)
                    const originTaskSid = getOriginalTaskSid(task)
                    const { participant } =
                        getCustomerParticipantData(originTaskSid)
                    const workerTask = widget.workerTask

                    await api.outsideColdTransfer({
                        call_sid: participant.callSid,
                        phone_number,
                    })

                    if (!workerTask) {
                        return
                    }

                    ousideEmitter.emit('callTransferedToExternalPhoneNumber', {
                        ...workerTaskToCallPayload(workerTask),
                        worker: worker.workerDetails,
                        externalPhoneNumber: phone_number,
                    })
                } catch (error: any) {
                    const message = error.message || error.error
                    widget.updateAcceptedCall((call) =>
                        setForwardingStatus(call, { error: message })
                    )
                }
            })
            .on('coldTransferToQueue', async (taskSid, queue_name) => {
                const widget = useWidgetStore()
                const { setForwardingStatus } = useAcceptedCall()
                widget.updateAcceptedCall((call) =>
                    setForwardingStatus(call, 'loading')
                )

                try {
                    const workerDetails = worker.workerDetails
                    const task = worker.getTaskBySid(taskSid)
                    const workerTask = widget.workerTask
                    const originTaskSid = getOriginalTaskSid(task)
                    const { participant } =
                        getCustomerParticipantData(originTaskSid)
                    await api.coldTransferToQueue({
                        call_sid: participant.callSid,
                        queue_name,
                        worker_sid: workerDetails.sid,
                        original_call_direction: getOriginalCallDirection(task),
                    })

                    if (!workerTask) {
                        return
                    }

                    ousideEmitter.emit('callTransferedToQueue', {
                        ...workerTaskToCallPayload(workerTask),
                        worker: worker.workerDetails,
                        transferToQueue: queue_name,
                    })
                } catch (error: any) {
                    console.log('ERROR =>', error)
                    const message = error.message || error.error
                    widget.updateAcceptedCall((call) =>
                        setForwardingStatus(call, { error: message })
                    )
                }
            })
            .on('loadWorkers', async () => {
                const widget = useWidgetStore()
                const { setAddParticipantWorkers, setForwardingStatus } =
                    useAcceptedCall()
                try {
                    const workerDetails = worker.workerDetails
                    widget.updateAcceptedCall((call) =>
                        setForwardingStatus(call, 'loading')
                    )
                    let workers = await workspace.fetchWorkers()
                    const shortWorkers = workers
                        .filter((worker) => worker.sid !== workerDetails.sid)
                        .sort((left) => {
                            if (left.available) {
                                return -1
                            }

                            return 0
                        })
                        .map((worker) => ({
                            sid: worker.sid,
                            friendlyName: worker.friendlyName,
                            activityName: worker.activityName,
                            available: worker.available,
                            identity: worker.attributes.contact_uri,
                        }))

                    widget.updateAcceptedCall((call) =>
                        setAddParticipantWorkers(call, shortWorkers)
                    )
                } catch (error: any) {
                    widget.updateAcceptedCall((call) =>
                        setForwardingStatus(call, { error: error.message })
                    )
                }
            })
            .on('warmPhoneNumberTransfer', async (taskSid, phoneNumber) => {
                const widget = useWidgetStore()
                const {
                    setAddParticipantSubmittedStatus,
                    toggleAddParticipant,
                } = useAcceptedCall()

                try {
                    const workerTask = getTaskBySid(taskSid)
                    const task = worker.getTaskBySid(taskSid)

                    if (!task) {
                        console.error(
                            `There is no task with this sid ${taskSid} in state`
                        )
                        return
                    }

                    const originTaskSid = getOriginalTaskSid(task)

                    const { conferenceSid, participant } =
                        getCustomerParticipantData(originTaskSid)

                    widget.updateAcceptedCall((call) =>
                        setAddParticipantSubmittedStatus(call, 'loading')
                    )

                    if (!participant.hold) {
                        await holdParticipant({
                            taskSid: originTaskSid,
                            identity: participant.identity,
                            callSid: participant.callSid,
                            conferenceSid: conferenceSid,
                            hold: true,
                        })
                    }
                    const response = await api.outsideWarmTransfer({
                        from: workerTask.phoneNumber.phoneNumber,
                        to: phoneNumber,
                        conference_sid: conferenceSid,
                        task_sid: taskSid,
                    })
                    ousideEmitter.emit('addExternalPhoneNumberParticipant', {
                        ...workerTaskToCallPayload(workerTask),
                        worker: worker.workerDetails,
                        externalPhoneNumber: phoneNumber,
                    })
                    await sync.addParticipant(originTaskSid, {
                        identity: phoneNumber,
                        callSid: response.call_sid,
                        participantLabel: response.label,
                        friendlyName: phoneNumber,
                    })
                    widget.updateAcceptedCall((call) =>
                        toggleAddParticipant(call)
                    )
                } catch (error: any) {
                    const message = error.message || error.error
                    widget.updateAcceptedCall((call) =>
                        setAddParticipantSubmittedStatus(call, {
                            error: message,
                        })
                    )
                }
            })
            .on('warmAgentTransfer', async (taskSid, agent) => {
                const widget = useWidgetStore()
                const {
                    setAddParticipantSubmittedStatus,
                    toggleAddParticipant,
                } = useAcceptedCall()

                try {
                    const workerDetails = worker.workerDetails
                    const workerTask = getTaskBySid(taskSid)
                    const task = worker.getTaskBySid(taskSid)
                    const originTaskSid = getOriginalTaskSid(task)
                    const { conferenceSid, participant } =
                        getCustomerParticipantData(originTaskSid)

                    widget.updateAcceptedCall((call) =>
                        setAddParticipantSubmittedStatus(call, 'loading')
                    )
                    if (!participant.hold) {
                        await holdParticipant({
                            taskSid: originTaskSid,
                            identity: participant.identity,
                            callSid: participant.callSid,
                            conferenceSid: conferenceSid,
                            hold: true,
                        })
                    }
                    await api.agentWarmTransfer({
                        task_sid: originTaskSid,
                        to_sid: agent.sid,
                        to_identity: agent.identity,
                        from_identity: workerDetails.identity,
                        from_friendly_name: workerDetails.friendlyName,
                        original_call_direction: getOriginalCallDirection(task),
                        customer_identity: participant.identity,
                        agent_phone_number: workerTask.phoneNumber.phoneNumber,
                    })
                    ousideEmitter.emit('addWorkerParticipant', {
                        ...workerTaskToCallPayload(workerTask),
                        worker: worker.workerDetails,
                        addedWorker: {
                            identity: agent.identity,
                            sid: agent.sid,
                            friendlyName: agent.friendlyName,
                        },
                    })
                    await sync.addParticipant(originTaskSid, {
                        identity: agent.identity,
                        callSid: '',
                        participantLabel: 'TransferAgent',
                        friendlyName: agent.friendlyName,
                    })
                    widget.updateAcceptedCall((call) =>
                        toggleAddParticipant(call)
                    )
                } catch (error: any) {
                    const message = error.message || error.error
                    widget.updateAcceptedCall((call) =>
                        setAddParticipantSubmittedStatus(call, {
                            error: message,
                        })
                    )
                }
            })
            .on('removeCallParticipant', async (taskSid, identity) => {
                const widget = useWidgetStore()
                const task = worker.getTaskBySid(taskSid)

                if (!task) {
                    console.error(
                        `There is no task with this sid ${taskSid} in state`
                    )
                    return
                }

                const originTaskSid = getOriginalTaskSid(task)
                const { conferenceSid, participant } = getParticipantData(
                    originTaskSid,
                    identity
                )

                try {
                    await sync.toggleHoldLoading(
                        originTaskSid,
                        participant.identity,
                        true
                    )
                    const workerTask = widget.workerTask
                    await api.removeParticipant({
                        conference_sid: conferenceSid,
                        call_sid: participant.callSid,
                        task_sid: originTaskSid,
                    })
                    if (workerTask) {
                        ousideEmitter.emit('removeParticipant', {
                            ...workerTaskToCallPayload(workerTask),
                            worker: worker.workerDetails,
                            participant: {
                                type:
                                    participant.participantLabel === 'Customer'
                                        ? 'client'
                                        : participant.participantLabel ===
                                            'ExternalParticipant'
                                          ? 'external'
                                          : 'worker',
                                identity: participant.identity,
                            },
                        })
                    }
                    await sync.updateParticipant(
                        originTaskSid,
                        participant.identity,
                        {
                            isTogglingHoldLoading: false,
                            callStatus: 'completed',
                        }
                    )
                } catch (error) {
                    console.log(error)
                    await sync.toggleHoldLoading(
                        originTaskSid,
                        participant.identity,
                        false
                    )
                }
            })
    }

    return {
        init,
    }
}
